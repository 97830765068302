<template>
  <nav class="text-lg flex flex-col items-start justify-center h-full py-0 text-white">
    <div class="font-bold px-6 mb-6">
      Nurie管理
    </div>
    <i class="fa-solid fa-messages" />
    <ul class="w-full">
      <li v-for="(item, index) in navItems" :key="`${item.path}-${index}`">
        <div v-if="item.role.includes(loginUser.role as AdminRole)" class="block rounded-md hover:bg-slate-700">
          <NuxtLink
            :to="item.path"
            class="w-full flex items-center px-6 py-2"
            active-class="bg-slate-600"
          >
            <div v-if="item.icon" class="mr-2">
              <i :class="'fa fa-' + item.icon" />
            </div>
            <div>{{ item.name }}</div>
          </NuxtLink>
        </div>
      </li>
    </ul>
  </nav>
</template>
<script lang="ts" setup>
import { PagePermit } from '../../static/const'

const route = useRoute()
const loginUser = useLoginAccount()
const userId = computed(() => Number(route.params.userId)).value
const navItems = computed<AdminItem[]>(() => {
  return PagePermit.map((v) => {
    return {
      ...v,
      path: `/apps/${userId}/${v.path}`
    }
  }).filter(v => v.show)
})
</script>
<style lang="scss" scoped>
.side-bar{
  height: 100vh;
}
</style>
