<template>
  <div
    v-if="show"
    class="relative z-30 w-full flex items-center pt-2 pb-2 px-6 text-base border-2"
    :class="{
      'bg-blue-100 border-blue-200 text-blue-900' : state.type === 'info',
      'bg-green-100 border-green-200 text-green-900' : state.type === 'success',
      'bg-red-100 border-red-200 text-red-900' : state.type === 'error',
      'bg-yellow-100 border-yellow-200 text-yellow-900' : state.type === 'warning',
    }"
  >
    <div class="flex justify-center items-end">
      <NotificationIcon :type="state.type" class="mr-2" />
      <span> {{ state.message }}</span>
    </div>
    <CloseIcon
      class="absolute right-3 top-3"
      @click="() =>{
        state.show = false
      }"
    />
  </div>
</template>
<script setup lang="ts">
import NotificationIcon from '~/components/icons/NotificationIcon.vue'
import CloseIcon from '~/components/icons/Close.vue'
const state = useToast()
const show = computed(() => {
  return state.value.show
})
const timeOut = ref()

watch(show, (c, prev) => {
  if (c !== prev && c) {
    sleep(state.value.time || 5000)
  }
})

function sleep (delay: number) {
  return new Promise(() => {
    timeOut.value = setTimeout(() => {
      state.value.show = false
      return Promise.resolve()
    }, delay)
  })
}
</script>
