<template>
  <div class="relative">
    <div class="flex items-start w-full">
      <div class="left-side sticky top-0 bg-gray-700 z-20 text-white py-3">
        <SideBar />
      </div>
      <main class="main bg-slate-500 relative">
        <div class="flex flex-col">
          <header class="sticky top-0 bg-white border-b border-b-slate-200 flex items-center justify-end z-10 px-8 py-3 h-16">
            <Toast />
            <!-- <HeaderSearch /> -->
            <!-- <HeaderNotification class="ml-2" /> -->
            <HeaderProfile class="ml-2" />
          </header>
          <div class="share-content bg-slate-100 overflow-y-auto h-full p-0">
            <slot />
          </div>
        </div>
      </main>
    </div>
    <Modal
      v-if="show"
      v-model="show"
      @update:model-value="(state)=>{
        message = 'ログインしてください'
      }"
    >
      <template #title>
        ログイン有効期限切れ
        <p v-if="message.length" class="mt-2 text-sm text-red-500 text-center">
          {{ message }}
        </p>
      </template>
      <template #body>
        <AccountLogin :full="true" :re-login-path="reLoginPath" />
      </template>
    </Modal>
  </div>
</template>
<script lang="ts" setup>
import SideBar from '~/components/template/SideBar.vue'
import HeaderSearch from '~/components/template/header/HeaderSearch.vue'
import HeaderNotification from '~/components/template/header/HeaderNotification.vue'
import HeaderProfile from '~/components/template/header/HeaderProfile.vue'
import Toast from '~/components/Toast.vue'
import Modal from '~/components/ModalBase.vue'
import AccountLogin from '~/components/template/AccountLogin.vue'

const loginDialog = useLoginDialog()
const route = useRoute()
const message = ref('')
const show = computed(() => {
  return loginDialog.value
})
const reLoginPath = computed(() => {
  return route.fullPath
})
</script>
<style lang="scss" scoped>
.left-side{
  min-width: 220px;
  min-height: 100vh;
  @apply text-base
}
.main{
  width: 100%;
  @apply text-base
}
.share-content{
  min-height: 100vh;
}
</style>
