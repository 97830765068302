<template>
  <div :id="elementId" class="relative">
    <div class="block rounded-sm py-1 px-4 border" @click="() => toggle = !toggle">
      <span title="プロフィール">
        <i class="fa-solid fa-user" />
      </span>
    </div>

    <div v-if="toggle" class="absolute top-9 right-0 bg-white shadow w-28">
      <ul class="w-full text-left whitespace-nowrap pt-2 pb-2">
        <li class="px-4 py-1 hover:bg-slate-200">
          <NuxtLink :to="toProfile">
            プロフィール
          </NuxtLink>
        </li>
        <li class="px-4 py-1 hover:bg-slate-200">
          <button @click="logout">
            ログアウト
          </button>
        </li>
      </ul>
    </div>
    <ClickOutsideCommon v-model="toggle" :element="element" />
  </div>
</template>
<script lang="ts" setup>
import ClickOutsideCommon from '~/components/ClickOutsideCommon.vue'
import { getElementCode } from '~/store/utils'
const route = useRoute()
const toProfile = computed(() => {
  return `/apps/${route.params.userId}/profile`
})
const toggle = ref(false)
const timeStamp = getElementCode()
const elementId = `profile-${timeStamp}`
const element = ref<HTMLElement>()

function logout () {
  commonFecth<void>('v1/auth/logout', 'POST').then(() => {
    useAuth().logout()
  })
}

onMounted(() => {
  element.value = document.getElementById(elementId) as HTMLDivElement
})
</script>
<style lang="scss" scoped>
</style>
